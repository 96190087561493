table {
    font-size: xx-small;
    border-collapse: collapse;
    width: 100%;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #DDD;
  }
  
  tr:hover {background-color: #D6EEEE;
}