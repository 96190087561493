.mermaidTooltip {
    position: absolute;
    text-align: center;
    max-width: 200px;
    padding: 2px;
    font-family: 'trebuchet ms', verdana, arial;
    font-size: 12px;
    background: #ffffde;
    border: 1px solid #aaaa33;
    border-radius: 2px;
    pointer-events: none;
    z-index: 100;
}

.react-tabs__tab{
    color: black;
    border: 1px solid #eee;
    border-radius: 5px 5px 0 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 1px;
    padding-top: 1px;
    font-size: xx-small;
    margin: 0;
  }

.react-tabs__tab--selected {
    color: black;
    border: 1px solid var(--blue-300);
    border-radius: 5px 5px 0 0;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 2px;
    padding-top: 2px;
    border-bottom: 3px solid var(--blue-600);
    font-size: xx-small;
    margin: 0;
    color: black;
    background-color: var(--blue-100);
  }

.react-tabs__tab:focus:after{
    content:none;
}

/* Add these styles to control visibility of tabs */
.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected,
.react-tabs__tab-panel.visible {
  display: block;
}

/* Add these styles to App.css */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--blue-600);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}