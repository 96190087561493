.p-tabview {
    display: flex;
}
.p-tabview-nav {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;    
    min-width: 210px;
    max-height: 300px;
}
.p-tabview-panels {
    float: right;
    position: relative;
    flex-grow: 1;
    width: 70%;
    min-width: 400px;
    min-height: 300px;
}